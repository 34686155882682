import 'styles/globals.css';
import 'styles/MentionList.scss';
import 'styles/tiptap.scss';

import Providers from 'provider/react-query-provider';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { UserInfoProvider } from 'provider/UserProvider';
import { SideNavProvider } from 'provider/SideNavProvider';
import { BreadcrumbProvider } from 'provider/BreadcrumbProvider';
import { ReactElement, ReactNode, useEffect } from 'react';
import {
  getCookies,
  setCookies,
  FloatingAlertProvider,
} from '@hol-jsp/dashboard-dsl';
import i18next from '../i18n';
import { Hydrate, setLogger } from 'react-query';
import * as Sentry from '@sentry/nextjs';
import { Font } from '@react-pdf/renderer';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'helper/graphql';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import Head from 'next/head';

Font.register({
  family: 'NunitoSans',
  src: '/fonts/nunito-sans/NunitoSans_10pt-Regular.ttf',
});
Font.register({
  family: 'NunitoSansMedium',
  src: '/fonts/nunito-sans/NunitoSans_10pt-Medium.ttf',
});
Font.register({
  family: 'NunitoSansSemibold',
  src: '/fonts/nunito-sans/NunitoSans_10pt-SemiBold.ttf',
});
Font.register({
  family: 'NunitoSansBold',
  src: '/fonts/nunito-sans/NunitoSans_10pt-Bold.ttf',
});

Font.register({
  family: 'Inter',
  src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf',
});
Font.register({
  family: 'InterSemiBold',
  src: '/fonts/inter/Inter-SemiBold.ttf',
});
Font.register({ family: 'InterMedium', src: '/fonts/inter/Inter-Medium.ttf' });
Font.register({ family: 'InterBold', src: '/fonts/inter/Inter-Bold.ttf' });

// export const metadata: Metadata = {
//   title: "Hukumonline DSL + Next App Router",
//   description: "Generated by create next app",
// };

setLogger({
  log: (message) => {
    Sentry.captureMessage(message);
  },
  warn: (message) => {
    Sentry.captureMessage(message);
  },
  error: (error) => {
    Sentry.captureException(error);
  },
});

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function CustomApp({
  Component,
  pageProps,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const renderComponent = () => getLayout(<Component {...pageProps} />);
  const { i18n } = useTranslation();
  useEffect(() => {
    let language = getCookies('language');
    if (!language) {
      language = 'en';
      setCookies({ key: 'language', value: language });
    }
    i18n.changeLanguage(language);
  }, [i18n]);

  return (
    <Providers>
      <Hydrate state={pageProps.dehydratedState}>
        <>
          <Head>
            <title>RCS - Hukumonline.com</title>
            <meta name="viewport" content="width=1920" />
            <link rel="icon" href="/images/favicon.png" />
          </Head>
          <main className="app">
            <ApolloProvider client={client}>
              <I18nextProvider i18n={i18next}>
                <UserInfoProvider>
                  <SideNavProvider>
                    <BreadcrumbProvider>
                      <FloatingAlertProvider>
                        {renderComponent()}
                      </FloatingAlertProvider>
                    </BreadcrumbProvider>
                  </SideNavProvider>
                </UserInfoProvider>
              </I18nextProvider>
            </ApolloProvider>
          </main>
        </>
      </Hydrate>
    </Providers>
  );
}
